import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import FireProtectWagon1 from "assets/produkter/laddvagn-bridgehill-batteribrand-kapell.jpeg";
import FireProtectWagon2 from "assets/produkter/laddvagn-bridgehill-batteribrand-kapell-sida.jpeg";
import FireProtectWall from "assets/produkter/brandskydd-laddare-bridgehill-kapell.jpeg";

export default function Highlight() {
  return (
    <>
      <section className="py-20 bg-blueGray-600 overflow-hidden">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg py-16 px-12 relative z-10">
            <div className="w-full lg:w-8/12">
              <p className="text-4xl text-center">
                <span role="img" aria-label="love">
                  🧯
                </span>
              </p>
              <h3 className="font-semibold text-3xl text-center">
                Nyhet: Brandsäkra produkter
              </h3>

              <div className="flex flex-col lg:flex-row mt-10">
                <div className="flex-1 lg:mr-4">
                  <p className="py-2">
                    Vi är glada att tillkännage vårt senaste samarbete med
                    Nordic Fire Protection, en ledande aktör inom brandsäkerhet.
                    Tillsammans producerar vi brandsäkra produkter som är
                    speciellt utformade för att skydda kablar och minska risken
                    för brand. För mer information om våra produkter och
                    tjänster, besök vår hemsida eller kontakta oss direkt.
                  </p>
                  <p className="py-2">
                    Deras produkter, såsom Bridgehill fire PRO X och laddvagnar
                    är kända för sin höga kvalitet och effektivitet1. Genom
                    detta samarbete strävar vi efter att erbjuda våra kunder de
                    bästa lösningarna för brandsäkerhet och skydda deras
                    verksamheter från potentiella brandrisker.
                  </p>
                </div>
                <div className="flex-1 lg:ml-4 mt-10 lg:mt-0">
                  <Carousel showThumbs={false} showStatus={false}>
                    <div>
                      <img
                        src={FireProtectWagon1}
                        alt="Slide 1"
                        className="carousel-image"
                      />
                    </div>
                    <div>
                      <img
                        src={FireProtectWagon2}
                        alt="Slide 2"
                        className="carousel-image"
                      />
                    </div>
                    <div>
                      <img
                        src={FireProtectWall}
                        alt="Slide 2"
                        className="carousel-image"
                      />
                    </div>
                  </Carousel>
                </div>
              </div>
              <div className="py-4">
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-orange-600 bg-orange-200 uppercase last:mr-0 mr-1">
                  Bridgehill
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-orange-600 bg-orange-200 uppercase last:mr-0 mr-1">
                  Car fire PRO X
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-orange-600 bg-orange-200 uppercase last:mr-0 mr-1">
                  Nordic fire protection
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-orange-600 bg-orange-200 uppercase last:mr-0 mr-1">
                  Bilbrand
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-orange-600 bg-orange-200 uppercase last:mr-0 mr-1">
                  Batteribrand
                </span>
              </div>
              <div className="mt-12">
                <a
                  href="/produkter/brandskydd"
                  className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Läs mer om brandskydd
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
