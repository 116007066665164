import React from "react";
import { Link } from "react-router-dom";
import repairImage from "assets/tjänster/repair.png";

export default function Repair() {
  return (
    <div className="w-full px-4 flex-1">
      <Link to="/produkter/reparation">
        <div className="relative flex flex-col min-w-0 break-words bg-blueGray w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500 transform hover:scale-105 ease-linear transition-all duration-150">
          <img
            alt="..."
            src={repairImage}
            className="w-full align-middle rounded-t-lg  object-fit-cover"
          />
          <blockquote className="relative p-8 mb-4">
            <svg
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 583 95"
              className="absolute left-0 w-full block h-95-px -top-94-px"
            >
              <polygon
                points="-30,95 583,95 583,65"
                className="text-lightBlue-500 fill-current"
              ></polygon>
            </svg>
            <h4 className="text-xl font-bold text-white">Reparationsarbete</h4>
            <p className="text-md font-light mt-2 text-white">
              Vår reparationstjänst är inriktad på att förlänga livslängden på
              dina produkter. Med expertkunskap och precision åtgärdar vi allt
              från mindre slitageskador till större revor och brott.
              <br></br>
              <br></br>
              Vi arbetar noggrant för att säkerställa att varje reparation
              uppfyller våra höga standarder för kvalitet och hållbarhet, och
              ger nytt liv åt dina värdefulla artiklar.
            </p>
          </blockquote>
        </div>
      </Link>
    </div>
  );
}
