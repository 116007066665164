/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import Contact from "components/Contact";
import Logo from "assets/img/logo3.png";

const Single = ({ content }) => {
  return (
    <>
      <div style={{ whiteSpace: "pre-line" }}>
        <Helmet>
          <title>{content.metaTitle}</title>
        </Helmet>
        <IndexNavbar fixed />
        <section className="bg-white overflow-hidden">
          <div className="container mx-auto pb-32">
            <div className="flex flex-wrap justify-center">
              <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
                <h1 className="text-3xl mb-2 font-semibold leading-normal">
                  {content.contentTitle}
                </h1>
                <p>{content.contentDescription}</p>
              </div>
              <div className="md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
                <img
                  className="rounded-lg"
                  src={content.image}
                  alt="..."
                  style={{
                    transform:
                      "scale(1) perspective(1040px) rotateY(-2deg) rotateX(2deg) rotate(2deg)",
                  }}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="relative bg-blueGray-200">
          <div className="container mx-auto px-4 pb-32 pt-48">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
                <div className="md:pr-12">
                  <h3 className="text-3xl font-semibold">
                    {content.contentHowTitle}
                  </h3>
                  <ul className="list-none mt-6">
                    <li className="py-3">
                      <div className="flex items-center">
                        <div>
                          <span className="text-3xl font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500  mr-3">
                            <i className="fas fa-1 "></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            {content.contentHowStep1}
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-3xl font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500  mr-3">
                            <i className="fas fa-2 "></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            {content.contentHowStep2}
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-3xl font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500  mr-3">
                            <i className="fas fa-3 "></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            {content.contentHowStep3}
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0 flex justify-center">
                <img
                  alt="..."
                  className="max-w-full "
                  style={{
                    transform:
                      "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                  }}
                  src={Logo}
                />
              </div>
            </div>
          </div>
        </section>
        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default Single;
